<template>
  <div>
    <div>
      <h1 class="complaint-head-text">Тоон үзүүлэлт</h1>
      <el-row :gutter="40" class="panel-group">
        <el-col :xs="12" :sm="12" :lg="5" class="card-panel-col">
          <el-carousel height="108px" :interval="3000" arrow="always">
            <el-carousel-item v-for="(product, indexProduct) in generateData('product')" :key="indexProduct">
              <div class="card-panel" @click="$router.push({ name: 'products' })">
                <div class="card-panel-icon-wrapper icon-people">
                  <i class="fas fa-cube card-panel-icon"></i>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">
                    {{ product.label }}
                  </div>
                  <count-to :start-val="0" :end-val="product.value" :duration="2600" class="card-panel-num" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="5" class="card-panel-col">
          <div class="card-panel" @click="$router.push({ name: 'inventory' })">
            <div class="card-panel-icon-wrapper icon-money">
              <i class="fas fa-cubes card-panel-icon"></i>
            </div>
            <div class="card-panel-description">
              <div class="card-panel-text">
                Хувилбар
              </div>
              <count-to :start-val="0" :end-val="data.totalVariant" :duration="2600" class="card-panel-num" />
            </div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="5" class="card-panel-col">
          <div class="card-panel" @click="$router.push({ name: 'inventory' })">
            <div class="card-panel-icon-wrapper icon-message">
              <i class="fas fa-download card-panel-icon"></i>
            </div>
            <div class="card-panel-description">
              <div class="card-panel-text">
                Үлдэгдэл
              </div>
              <count-to :start-val="0" :end-val="data.totalCount" :duration="2600" suffix="ш" class="card-panel-num" />
            </div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
          <div class="card-panel" @click="$router.push({ name: 'transfer' })">
            <div class="card-panel-icon-wrapper icon-shopping">
              <i class="fas fa-exchange-alt card-panel-icon"></i>
            </div>
            <div class="card-panel-description">
              <div class="card-panel-text">
                Таталт
              </div>
              <count-to :start-val="0" :end-val="data.totalPendingTransfer + data.totalPartialTransfer" :duration="2600" prefix="(" suffix=")" class="card-panel-num" />
              <count-to :start-val="0" :end-val="data.numberToBeTransferred" :duration="2600" class="card-panel-num" />
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="40" class="panel-group">
        <el-col :xs="12" :sm="12" :lg="7" class="card-panel-col">
          <el-carousel height="108px" :interval="4000" arrow="always">
            <el-carousel-item v-for="(supplier, indexSupplier) in generateData('supplier')" :key="indexSupplier">
              <div class="card-panel" @click="$router.push({ name: 'supplier' })">
                <div class="card-panel-icon-wrapper icon-supplier">
                  <i class="fas fa-store-alt card-panel-icon"></i>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">
                    {{ supplier.label }}
                  </div>
                  <count-to :start-val="0" :end-val="supplier.value" :duration="2600" class="card-panel-num" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="7" class="card-panel-col">
          <el-carousel height="108px" :interval="3500" arrow="always">
            <el-carousel-item v-for="(brand, indexBrand) in generateData('brand')" :key="indexBrand">
              <div class="card-panel" @click="$router.push({ name: 'brandList' })">
                <div class="card-panel-icon-wrapper icon-brand">
                  <i class="fab fa-apple card-panel-icon"></i>
                </div>
                <div class="card-panel-description">
                  <div class="card-panel-text">
                    {{brand.label}}
                  </div>
                  <count-to :start-val="0" :end-val="brand.value" :duration="2600" class="card-panel-num" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="7" class="card-panel-col">
          <div class="card-panel" @click="$router.push({ name: 'warehouseList' })">
            <div class="card-panel-icon-wrapper icon-warehouse">
              <i class="fas fa-warehouse card-panel-icon"></i>
            </div>
            <div class="card-panel-description">
              <div class="card-panel-text">
                Агуулах
              </div>
              <count-to :start-val="0" :end-val="data.totalWarehouse" :duration="2600" class="card-panel-num" />
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="5">&nbsp;&nbsp;.&nbsp;</el-col>
        <el-col :span="12">
          <el-card shadow="always" style="text-align: center">
            <el-avatar
              style="width: 100px; height: 100px"
              src="https://www.pngkey.com/png/full/694-6947029_avatar-people-no-background-png-people-icon-03.png"
            >
            </el-avatar>
            <h3 style="font-weight: bold">
              Тавтай морил {{ this.user_email }}
            </h3>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import service from '@/helpers/services.js'
import { getUser } from '@/utils/auth.js'
export default {
  name: 'SolvedComplaints',
  components: {
    CountTo
  },
  mounted () {
    this.getStats()
    getUser().then((user) => {
      this.user_email = user.username
    })
  },
  data () {
    return {
      user_email: '',
      data: {}
    }
  },
  methods: {
    generateData (type) {
      const productData = [
        {
          label: 'Нийт бараа',
          value: this.data.totalProduct
        },
        {
          label: 'Идэвхтэй',
          value: this.data.activeProduct
        },
        {
          label: 'Идэвхгүй',
          value: this.data.inactiveProduct
        },
        {
          label: 'Брэнд хаалттай',
          value: this.data.brandInactiveProduct
        },
        {
          label: 'Үнэ батлаагүй',
          value: this.data.priceInactiveProduct
        }
      ]
      const supplierData = [
        {
          label: 'Нийлүүлэгч',
          value: this.data.totalSupplier
        },
        {
          label: 'Гэрээ баталсан',
          value: this.data.contractAcceptedSupplier
        },
        {
          label: 'Гэрээ цуцалсан',
          value: this.data.contractDeniedSupplier
        },
        {
          label: 'Гэрээ хүлээгдэж байгаа',
          value: this.data.contractPendingSupplier
        }
      ]
      const brandData = [
        {
          label: 'Брэнд',
          value: this.data.totalBrand
        },
        {
          label: 'Идэвхтэй брэнд',
          value: this.data.activeBrand
        },
        {
          label: 'Идэвхгүй брэнд',
          value: this.data.inactiveBrand
        }
      ]
      if (type === 'product') {
        return productData
      } else if (type === 'brand') {
        return brandData
      } else {
        return supplierData
      }
    },
    getStats () {
      service.getStats().then((response) => {
        if (response.status === 'success') {
          this.data = response.data
        } else {
          this.$message.error('Уучдаарай , гомдлын тайлан татаж чадсангүй.')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;
  .card-panel-col {
    margin-bottom: 32px;
  }
  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }
      .icon-supplier {
        background: #d11141;
      }
      .icon-brand {
        background: #00b159
      }
      .icon-warehouse {
        background: #00aedb
      }
      .icon-people {
        background: #40c9c6;
      }
      .icon-message {
        background: #36a3f7;
      }
      .icon-money {
        background: #f4516c;
      }
      .icon-shopping {
        background: #34bfa3
      }
    }
    .icon-supplier {
      color: #d11141;
    }
    .icon-brand {
      color: #00b159
    }
    .icon-warehouse {
      color: #00aedb
    }
    .icon-people {
      color: #40c9c6;
    }
    .icon-message {
      color: #36a3f7;
    }
    .icon-money {
      color: #f4516c;
    }
    .icon-shopping {
      color: #34bfa3
    }
    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }
    .card-panel-icon {
      float: left;
      font-size: 48px;
    }
    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;
      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 18px;
        margin-bottom: 12px;
      }
      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}
@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }
  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
